// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import { confetti } from 'dom-confetti';

export default class extends Controller {
  connect() {
    const config = {
      angle: 90,
      spread: 360,
      startVelocity: 40,
      elementCount: "199",
      dragFriction: 0.12,
      duration: 3000,
      stagger: 3,
      width: "10px",
      height: "10px",
      perspective: "744px",
    };
    //const button = document.querySelector(".my-button")
    const button = document.body.querySelector(".confetti")

    //button.addEventListener("click", () => confetti(button, config))
    confetti(button, config)
  }
}
